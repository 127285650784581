.page{
    background:white,
}

.container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    // margin-top: 1rem;
    margin-top: 80px;
    padding: 0 0 1.25rem;
}

.containerRsp1{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    // margin-top: 5rem;
    margin-top: 80px;
    padding: 0 0 8.25rem,
}

.containerRspFeed{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    // margin-top: 5rem;
    margin-top: 80px;
}
